<template>
  <div id="app">
    <TUIKit />
    <!-- <TUICallKit class="callkit-container" :allowedMinimized="true" :allowedFullScreen="false" /> -->
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { TUILogin } from '@tencentcloud/tui-core';
import { framework } from './TUIKit/adapter-vue';

import {
  TUIConversationService
} from '@tencentcloud/chat-uikit-engine';

import { genTestUserSig } from '../utils/GenerateTestUserSig';
import { TUIKit } from './TUIKit';
import { getURLParams } from '../utils/index';
const query = getURLParams()

console.log(query)
const uid = query.uid && decodeURIComponent(window.atob(query.uid))
const sid = query.sid && decodeURIComponent(window.atob(query.sid))

const SDKAppID = 1600034793
const secretKey = '4c24f103968e66fe5f7117aab4a310189bc82d17c69f4c42e8e5fe6b0a783558'
const { userSig } = genTestUserSig({
  SDKAppID: SDKAppID,
  secretKey: secretKey,
  userID: uid
})

const login = () => {
  TUILogin.login({
    SDKAppID: SDKAppID,
    userID: uid,
    userSig: userSig,
    useUploadPlugin: true,
    framework,
  }).then(() => {
    console.log('登录成功')
    sid && TUIConversationService.switchConversation(`C2C${sid}`);
  }).catch((error) => { 
    console.log(error)
  })
}

onMounted(() => { 
  login()
})

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
