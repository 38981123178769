export function getURLParams() {
  var url = window.location.href;
  var params = url.split('?')[1];
  var paramsObject = {};

  if (params) {
      var keyValuePairs = params.split('&');
      keyValuePairs.forEach(function(keyValuePair) {
          var pair = keyValuePair.split('=');
          var key = decodeURIComponent(pair[0]);
          var value = decodeURIComponent(pair[1]);
          paramsObject[key] = value;
      });
  }

  return paramsObject;
}